import { array, boolean, custom, literal, nullable, number, object, optional, partial, string, union } from "valibot"

import { activationStatusSchema, functionalitiesSchema } from "@/modules/communication/schemas"
import { i18nLocaleSchema } from "@/modules/i18n/schemas"

// https://github.com/fabian-hiller/valibot/discussions/620#discussioncomment-9641537
const colorValueHexSchema = custom<`#${string}`>(input => typeof input === "string" && input.startsWith("#"))

export const CCThemeColorsSchema = object({
  boxTitleColor: colorValueHexSchema,
  buttonTextColor: colorValueHexSchema,
  headerColor: colorValueHexSchema,
  headerTextColor: colorValueHexSchema,
  mainColor: colorValueHexSchema,
  mainDarkenColor: colorValueHexSchema,
  mainTextColor: colorValueHexSchema,
})

const emptyCCThemeColorsSchema = object({
  boxTitleColor: literal(""),
  buttonTextColor: literal(""),
  headerColor: literal(""),
  headerTextColor: literal(""),
  mainColor: literal(""),
  mainDarkenColor: literal(""),
  mainTextColor: literal(""),
})

export const careerCenterModulesSchema = object({
  hasAdvices: optional(boolean()),
  hasAppointments: optional(boolean()),
  hasAppointmentsLaunchedInFo: optional(boolean()),
  hasArticles: optional(boolean()),
  hasEnrolment: optional(boolean()),
  hasEventHighlightPartners: optional(boolean()),
  hasEvents: optional(boolean()),
  hasGenericPositions: optional(boolean()),
  /** @deprecated Use hasEventHighlightPartners or hasJobadHighlightPartners instead. */
  hasHighlightPartners: optional(boolean()),
  hasJobOffers: optional(boolean()),
  hasJobadHighlightPartners: optional(boolean()),
  hasLearningPlatformAccess: optional(boolean()),
  hasRssFeed: optional(boolean()),
})

export const careerCenterThemeSchema = union([CCThemeColorsSchema, emptyCCThemeColorsSchema])

export const careerCenterConfSchema = object({
  careerCenterNameLocalized: optional(string()),
  customResourceLocale: optional(nullable(string())),
  hasPartners: boolean(),
  locales: array(i18nLocaleSchema),
  modules: careerCenterModulesSchema,
  positionCategoryIds: array(string()),
  postOfferFromFrontOffice: boolean(),
  theme: careerCenterThemeSchema,
  timeZoneIdentifier: optional(string()),
})

const mainCareerCenterConfSchema = object({
  hasMainCareerCenterArticles: optional(boolean()),
  mainCareerCenterCustomResourceLocale: optional(nullable(string())),
})

const associatedCareerCenterSchema = object({
  id: string(),
  name: string(),
})

export const careerCenterSchema = object({
  associatedCareerCenters: array(associatedCareerCenterSchema),
  careerCenterConf: nullable(careerCenterConfSchema),
  id: string(),
  /** @deprecated Use the "id" property instead. */
  idOld: number(),
  intranet: boolean(),
  logo: string(),
  mainCareerCenterConf: optional(nullable(mainCareerCenterConfSchema)),
  mainCareerCenterId: optional(string()),
  mainCareerCenterIdOld: optional(number()),
  name: string(),
  secondaryLogo: optional(string()),
  shortName: string(),
})

export const schoolCommunicationStatusSchema = object({
  activationStatus: activationStatusSchema,
  error: optional(string()),
  functionalities: partial(functionalitiesSchema),
})
