import type { TrackEventProperties, TrackEventType } from "@/modules/tracking/types"

import { useTracking } from "@/modules/tracking/useTracking"

export const useTrackEvent = (): TrackEventType => {
  const tracking = useTracking()

  return (eventName: string, eventProps: TrackEventProperties) => {
    tracking?.track(eventName, { ...eventProps })
  }
}
