import { boolean, literal, object, union } from "valibot"

// School communication module status

export const functionalitiesSchema = object({
  contactsEnabled: boolean(),
})

export const activationStatusSchema = union([
  literal("ACTIVATION_STATUS_UNDEFINED_VALUE_UNSPECIFIED"),
  literal("ACTIVATION_STATUS_ACTIVE"),
  literal("ACTIVATION_STATUS_PENDING_ACTIVATION"),
  literal("ACTIVATION_STATUS_PENDING_INACTIVATION"),
  literal("ACTIVATION_STATUS_INACTIVE"),
  literal("ACTIVATION_STATUS_ERROR"),
])
