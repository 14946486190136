import type { LayoutProps } from "@/modules/corporate/components/Layout/types"
import type { PagesRouterPage, PagesRouterPageProps } from "@/types"
import type { GetServerSideProps } from "next"

import { Layout } from "@/modules/corporate/components/Layout/Layout"
import { getLayoutServerSideProps } from "@/modules/corporate/server/getLayoutServerSideProps"

type CorporatePageType = PagesRouterPage<LayoutProps>

const CorporatePage: CorporatePageType = props => <Layout {...props} />

export const getServerSideProps: GetServerSideProps<PagesRouterPageProps<LayoutProps>> = getLayoutServerSideProps

export default CorporatePage
