import type { BannerProps } from "@/modules/corporate/components/Layout/Banner"
import type { LayoutProps } from "@/modules/corporate/components/Layout/types"
import type { PagesRouterPage } from "@/types"

import cn from "classnames"
import dynamic from "next/dynamic"
import { useSearchParams } from "next/navigation"
import { useEffect, useMemo, useState } from "react"

import { Header } from "@/modules/corporate/components/Header/Header"
import { PageContent } from "@/modules/corporate/components/Layout/PageContent"
import {
  ALLOWED_TRACKING_PARAMETERS,
  SESSION_STORAGE_CAMPAIGN_TRACKING_DATA_KEY,
  SESSION_STORAGE_REFERRER_TRACKING_KEY,
  URL_PATHNAME,
} from "@/modules/corporate/constants"
import { Footer } from "@/modules/headerFooterFO/Footer/Footer"

import { Metadata } from "./Metadata"

import styles from "./Layout.module.css"

const DynamicBanner = dynamic<BannerProps>(() => import("./Banner").then(mod => mod.BannerComponent))

export const Layout: PagesRouterPage<LayoutProps> = ({
  page,
  pageBanner,
  pageNavigation,
  pagePagination,
  pageTranslations,
  personas,
  serverContext,
  subPage,
}) => {
  const searchParams = useSearchParams()
  const search = useMemo(() => new URLSearchParams(searchParams), [searchParams])

  const [isMobileMenuOpened, setMobileMenuOpened] = useState<boolean>(false)
  const currentPersonaRootPage = page.persona.length
    ? personas.find(({ persona }) => persona[0] === page.persona[0])
    : null

  // Check tracking url parameters to save them inside `sessionStorage`
  useEffect(() => {
    for (const key of search.keys()) {
      if (!ALLOWED_TRACKING_PARAMETERS.includes(key)) search.delete(key)
    }

    const searchSize = [...new Set(search.keys())].length
    if (searchSize > 0) {
      window.sessionStorage.setItem(SESSION_STORAGE_CAMPAIGN_TRACKING_DATA_KEY, search.toString())
    }

    if (!window.sessionStorage.getItem(SESSION_STORAGE_REFERRER_TRACKING_KEY)) {
      window.sessionStorage.setItem(SESSION_STORAGE_REFERRER_TRACKING_KEY, document.referrer)
    }
  }, [search])

  return (
    <div className={cn(styles.container, { [styles["container--menuOpen"]]: isMobileMenuOpened })}>
      <Metadata
        currentPersonaRootPage={currentPersonaRootPage}
        page={page}
        serverContext={serverContext}
        subPage={subPage}
        pageTranslations={pageTranslations}
      />
      <Header
        currentPersonaRootPage={currentPersonaRootPage}
        isMobileMenuOpened={isMobileMenuOpened}
        page={page}
        pageNavigation={pageNavigation}
        pageTranslations={pageTranslations}
        personas={personas}
        onNavigationLinkClick={() => {
          setMobileMenuOpened(false)
        }}
        toggleMenu={() => {
          setMobileMenuOpened(!isMobileMenuOpened)
        }}
      />
      {pageBanner && <DynamicBanner banner={pageBanner} />}
      <main className={styles.mainContent}>
        <PageContent
          currentPersonaRootPage={currentPersonaRootPage}
          page={page}
          pagePagination={pagePagination}
          subPage={subPage}
        />
      </main>
      <Footer
        pageTranslations={Object.fromEntries<string>(
          Object.entries(pageTranslations).map(([pageTranslationLocale, pageTranslationSlug]) => [
            pageTranslationLocale,
            `/${pageTranslationLocale}/${URL_PATHNAME}/${pageTranslationSlug}`,
          ])
        )}
      />
    </div>
  )
}
