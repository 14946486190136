
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[pageLocale]/corporate/[[...url]]",
      function () {
        return require("private-next-pages/[pageLocale]/corporate/[[...url]].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[pageLocale]/corporate/[[...url]]"])
      });
    }
  