import type { CareerCenterTheme } from "./types"

import { isEmpty } from "@/modules/globalObjects/isEmpty"

export function hasCustomTheme(theme?: CareerCenterTheme): boolean {
  if (!theme || isEmpty(theme)) {
    return false
  }

  // Check if the main color is filled
  return !!theme.mainColor
}
