import type { RichTextElementCustomProps } from "./RichTextElement"
import type { ResolvedRichTextValue } from "@/modules/kontent/types"
import type { TextProps } from "@jobteaser/ui-kit/components/Text/Text"
import type { FunctionComponent } from "react"

import classNames from "classnames"

import { Text } from "@jobteaser/ui-kit/components/Text/Text"

import { RichTextElement } from "./RichTextElement"

import styles from "./RichText.module.css"

type RichTextProps = RichTextElementCustomProps & {
  value: ResolvedRichTextValue
  wrapperProps: TextProps<unknown>
}

export const RichText: FunctionComponent<RichTextProps> = ({
  customComponents,
  tagComponents,
  value,
  wrapperProps,
}) => {
  const { className, ...props } = wrapperProps

  return (
    <Text {...props} className={classNames(styles.main, className)}>
      {value.map((elementProps, index) => (
        <RichTextElement
          {...elementProps}
          // eslint-disable-next-line react/no-array-index-key
          key={`${elementProps.type}_${index}`}
          customComponents={customComponents}
          tagComponents={tagComponents}
        />
      ))}
    </Text>
  )
}
