import type { Asset } from "./types"
import type { MutableRefObject } from "react"

import { useEffect, useState } from "react"

import { getAssetImportUrl } from "./getAssetImportUrl"

const BLANK_IMAGE = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="

export function useLazyAssetUrl({
  asset,
  ref,
  options = {},
}: {
  asset: Asset
  ref: MutableRefObject<HTMLElement | null>
  options?: IntersectionObserverInit
}): string {
  const assetUrl = getAssetImportUrl(asset)
  const needsLazyLoading = !assetUrl.startsWith("data:")
  const [isObserved, setIsObserved] = useState(false)

  const onObserve: IntersectionObserverCallback = entries => {
    if (!isObserved) {
      for (const entry of entries) {
        if (ref.current && entry.isIntersecting) {
          setIsObserved(true)
        }
      }
    }
  }

  useEffect(() => {
    let observedValue: Element | null = null
    let observer: IntersectionObserver | null = null

    if (needsLazyLoading) {
      observer = new IntersectionObserver(onObserve, options)

      if (ref.current) {
        observer.observe(ref.current)
        observedValue = ref.current
      }
    }

    return () => {
      if (needsLazyLoading && observedValue && observer) {
        observer.unobserve(observedValue)
      }
    }
  })

  return isObserved ? assetUrl : BLANK_IMAGE
}
