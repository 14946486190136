import type { LayoutProps } from "./types"
import type { BlogPropsType } from "@/modules/corporate/components/Sections/Blog/Blog"
import type { CardPropsType } from "@/modules/corporate/components/Sections/Card"
import type { ChiliPiperPropsType } from "@/modules/corporate/components/Sections/ChiliPiper"
import type { DetailedCardPropsType } from "@/modules/corporate/components/Sections/DetailedCard"
import type { FAQPropsType } from "@/modules/corporate/components/Sections/FrequentlyAskedQuestions"
import type { HeroPropsType } from "@/modules/corporate/components/Sections/Hero/Hero"
import type { JTCodePropsType } from "@/modules/corporate/components/Sections/JTCode/JTCode"
import type { LinksPropsType } from "@/modules/corporate/components/Sections/Links/Links"
import type { MediasPropsType } from "@/modules/corporate/components/Sections/Medias/Medias"
import type { NetworkPropsType } from "@/modules/corporate/components/Sections/Network/Network"
import type { PartnersPropsType } from "@/modules/corporate/components/Sections/Partners/Partners"
import type { PricingPropsType } from "@/modules/corporate/components/Sections/Pricing/Pricing"
import type { RecruiterFormPropsType } from "@/modules/corporate/components/Sections/RecruiterForm"
import type { RelevantDataPropsType } from "@/modules/corporate/components/Sections/RelevantData/RelevantData"
import type { RichTextPropsType } from "@/modules/corporate/components/Sections/Richtext/RichText"
import type { TestimonyPropsType } from "@/modules/corporate/components/Sections/Testimony/Testimony"
import type { ThreeSlidesScrollPropsType } from "@/modules/corporate/components/Sections/ThreeSlidesScroll"
import type { TimelinePropsType } from "@/modules/corporate/components/Sections/Timeline/Timeline"
import type { UniversityFormPropsType } from "@/modules/corporate/components/Sections/UniversityForm"
import type { VerticalSliderPropsType } from "@/modules/corporate/components/Sections/VerticalSlider/VerticalSlider"
import type { VideoPropsType } from "@/modules/corporate/components/Sections/Video"
import type { BlogArticle, BlogArticleSummary, BlogFilters } from "@/modules/corporate/types/blog"
import type { PagePagination } from "@/modules/corporate/types/pagePagination"
import type { Page } from "@/modules/corporate/types/pages"
import type { SchoolData, SchoolFilters } from "@/modules/corporate/types/schools"
import type { FunctionComponent } from "react"

// This ui-kit component is used accross the sections but as they are dinamically loaded, this
// module is duplicated in each chunk. Importing it without usage adds it in a separated chunk and
// avoids the duplication.
import "@jobteaser/ui-kit/dist/module/components/Layout/Layout"

import dynamic from "next/dynamic"

import { getTextFromRichText } from "@/modules/kontent/textsFromRichTextElements"

type Props = Pick<LayoutProps, "page" | "pagePagination" | "subPage"> & {
  currentPersonaRootPage: Page | null | undefined
}

const DynamicBlog = dynamic<BlogPropsType>(() => import("../Sections/Blog/Blog").then(mod => mod.Blog))
const DynamicCard = dynamic<CardPropsType>(() => import("../Sections/Card").then(mod => mod.CardWrapper))
const DynamicChiliPiper = dynamic<ChiliPiperPropsType>(() =>
  import("../Sections/ChiliPiper").then(mod => mod.ChiliPiper)
)
const DynamicDetailedCard = dynamic<DetailedCardPropsType>(() =>
  import("../Sections/DetailedCard").then(mod => mod.DetailedCardWrapper)
)
const DynamicFAQ = dynamic<FAQPropsType>(() =>
  import("../Sections/FrequentlyAskedQuestions").then(mod => mod.FrequentlyAskedQuestions)
)
const DynamicHero = dynamic<HeroPropsType>(() => import("../Sections/Hero/Hero").then(mod => mod.Hero))
const DynamicJTCode = dynamic<JTCodePropsType>(() => import("../Sections/JTCode/JTCode").then(mod => mod.JTCode))
const DynamicLinks = dynamic<LinksPropsType>(() => import("../Sections/Links/Links").then(mod => mod.Links))
const DynamicMedias = dynamic<MediasPropsType>(() => import("../Sections/Medias/Medias").then(mod => mod.Medias))
const DynamicNetwork = dynamic<NetworkPropsType>(() => import("../Sections/Network/Network").then(mod => mod.Network))
const DynamicNewsletterForm = dynamic(() => import("../Sections/NewsletterForm").then(mod => mod.NewsletterForm))
const DynamicPartners = dynamic<PartnersPropsType>(() =>
  import("../Sections/Partners/Partners").then(mod => mod.Partners)
)
const DynamicPricing = dynamic<PricingPropsType>(() => import("../Sections/Pricing/Pricing").then(mod => mod.Pricing))
const DynamicRecruiterForm = dynamic<RecruiterFormPropsType>(() =>
  import("../Sections/RecruiterForm").then(mod => mod.RecruiterForm)
)
const DynamicRelevantData = dynamic<RelevantDataPropsType>(() =>
  import("../Sections/RelevantData/RelevantData").then(mod => mod.RelevantData)
)
const DynamicRichText = dynamic<RichTextPropsType>(() =>
  import("../Sections/Richtext/RichText").then(mod => mod.RichText)
)
const DynamicTestimony = dynamic<TestimonyPropsType>(() =>
  import("../Sections/Testimony/Testimony").then(mod => mod.Testimony)
)
const DynamicThreeSlidesScroll = dynamic<ThreeSlidesScrollPropsType>(() =>
  import("../Sections/ThreeSlidesScroll").then(mod => mod.ThreeSlidesScroll)
)
const DynamicTimeline = dynamic<TimelinePropsType>(() =>
  import("../Sections/Timeline/Timeline").then(mod => mod.Timeline)
)
const DynamicUniversityForm = dynamic<UniversityFormPropsType>(() =>
  import("../Sections/UniversityForm").then(mod => mod.UniversityForm)
)
const DynamicVerticalSlider = dynamic<VerticalSliderPropsType>(() =>
  import("../Sections/VerticalSlider/VerticalSlider").then(mod => mod.VerticalSlider)
)
const DynamicVideo = dynamic<VideoPropsType>(() => import("../Sections/Video").then(mod => mod.Video))

export const PageContent: FunctionComponent<Props> = ({ currentPersonaRootPage, page, pagePagination, subPage }) => (
  <>
    {(page.sections || [])
      .map(section => {
        switch (section.type) {
          case "growth___corporate_page_section___3_slides_scroll":
            return (
              <DynamicThreeSlidesScroll
                key={`${section.type}_${getTextFromRichText(section.title)}`}
                section={section}
              />
            )
          case "growth___corporate_page_section___blog":
            return (
              <DynamicBlog
                currentPersonaRootPage={currentPersonaRootPage}
                key={`${section.type}`}
                page={page}
                pagePagination={pagePagination as PagePagination<BlogArticleSummary, BlogFilters>}
                section={section}
                subPage={subPage as BlogArticle}
              />
            )
          case "growth___corporate_page_section___card":
            return <DynamicCard key={`${section.type}_${section.title}`} section={section} />
          case "growth___corporate_page_section___chilipiper":
            return <DynamicChiliPiper key={`${section.type}`} section={section} />
          case "growth___corporate_page_section___detailed_card":
            return (
              <DynamicDetailedCard key={`${section.type}_${getTextFromRichText(section.title)}`} section={section} />
            )
          case "growth___corporate_page_section___faq":
            return <DynamicFAQ key={`${section.type}_${section.title}`} section={section} />
          case "growth___corporate_page_section___hero":
            return (
              <DynamicHero
                key={`${section.type}_${section.title}`}
                pageTitle={page.title}
                isRootPage={page.isPersonaRoot.includes("true")}
                section={section}
              />
            )
          case "growth___corporate_page_section___jt_code":
            return <DynamicJTCode key={`${section.type}_${section.title}`} section={section} />
          case "growth___corporate_page_section___links":
            return <DynamicLinks key={`${section.type}_${section.title}`} section={section} />
          case "growth___corporate_page_section___medias":
            return <DynamicMedias key={`${section.type}_${section.title}`} section={section} />
          case "growth___corporate_page_section___network":
            return (
              <DynamicNetwork
                key={`${section.type}_${section.title}`}
                page={page}
                section={section}
                pagePagination={pagePagination as PagePagination<SchoolData, SchoolFilters>}
              />
            )
          case "growth___corporate_page_section___newsletter_form":
            return <DynamicNewsletterForm key={`${section.type}_${section.title}`} section={section} />
          case "growth___corporate_page_section___partners":
            return <DynamicPartners key={`${section.type}_${section.title}`} section={section} />
          case "growth___corporate_page_section___pricing":
            return <DynamicPricing key={`${section.type}_${section.title}`} section={section} />
          case "growth___corporate_page_section___recruiter_form":
            return <DynamicRecruiterForm key={`${section.type}_${section.title}`} section={section} />
          case "growth___corporate_page_section___relevant_data":
            return <DynamicRelevantData key={`${section.type}_${section.title}`} section={section} />
          case "growth___corporate_page_section___rich_text":
            return <DynamicRichText key={`${section.type}_${section.title}`} section={section} />
          case "growth___corporate_page_section___testimony":
            return <DynamicTestimony key={`${section.type}_${section.title}`} section={section} />
          case "growth___corporate_page_section___timeline":
            return <DynamicTimeline key={`${section.type}_${section.title}`} section={section} />
          case "growth___corporate_page_section___university_form":
            return <DynamicUniversityForm key={`${section.type}_${section.title}`} section={section} />
          case "growth___corporate_page_section___vertical_slider":
            return <DynamicVerticalSlider key={`${section.type}_${section.title}`} section={section} />
          case "growth___corporate_page_section___video":
            return <DynamicVideo key={`${section.type}_${section.title}`} section={section} />
          default:
            return null
        }
      })
      .filter(Boolean)}
  </>
)
