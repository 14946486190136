import type { LayoutProps } from "@/modules/corporate/components/Layout/types"
import type { Page } from "@/modules/corporate/types/pages"
import type { PagesRouterPageProps } from "@/types"
import type { FunctionComponent } from "react"

import Head from "next/head"
import { usePathname } from "next/navigation"

import { URL_PATHNAME } from "@/modules/corporate/constants"
import defaultImage from "@/modules/corporate/images/metadata-default-image.jpg"
import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { useLocale } from "@/modules/locales/useLocale"
import { useHref } from "@/modules/routing/hooks/useHref"
import { getAssetImportUrl } from "@/modules/staticAssets/getAssetImportUrl"

type MetadataProps = Pick<
  PagesRouterPageProps<LayoutProps>,
  "page" | "subPage" | "serverContext" | "pageTranslations"
> & {
  currentPersonaRootPage: Page | null | undefined
}

export const Metadata: FunctionComponent<MetadataProps> = ({
  serverContext,
  currentPersonaRootPage,
  page,
  pageTranslations,
  subPage,
}) => {
  const { t } = useTranslation(["module_header_fo"])
  const href = useHref(true)
  const pathname = usePathname()
  const locale = useLocale()
  const { baseUrl } = serverContext || { baseUrl: "" }
  const title = `${(subPage || page).metaTitle || (subPage || page).title} | JobTeaser`
  const description = subPage?.metaDescription || page.metaDescription
  const canonicalUrl = `${baseUrl}${pathname}`
  const isArticleType = (page.sections || []).find(({ type }) => type === "growth___corporate_page_section___blog")
  const pageImage = subPage ? subPage.image : page.image
  const imageUrl = pageImage.length
    ? pageImage[0].url
    : `${process.env.NEXT_PUBLIC_ENV === "local" ? baseUrl : ""}${getAssetImportUrl(defaultImage)}`
  const imageAlt = pageImage.length ? pageImage[0].description : null
  const jsonLD = subPage && {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        item: `${baseUrl}/${locale}/${URL_PATHNAME}${currentPersonaRootPage ? `/${page.slug}` : ""}`,
        name: currentPersonaRootPage ? currentPersonaRootPage.title : t("module_header_fo.home"),
        position: 1,
      },
      {
        "@type": "ListItem",
        item: `${baseUrl}/${locale}/${URL_PATHNAME}/${page.slug}`,
        name: page.title,
        position: 2,
      },
      subPage.parentPage && subPage.parentPage.length
        ? {
            "@type": "ListItem",
            item: `${baseUrl}/${locale}/${URL_PATHNAME}/${subPage.parentPage[0].slug}`,
            name: subPage.parentPage[0].title,
            position: 3,
          }
        : null,
      {
        "@type": "ListItem",
        name: subPage.title,
        position: subPage.parentPage && subPage.parentPage.length ? 4 : 3,
      },
    ].filter(Boolean),
  }

  return (
    <Head>
      {/* Standard HTML */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} hrefLang={locale} />
      {Object.entries(pageTranslations).map(([otherLocale, slug]) => (
        <link
          key={otherLocale}
          rel="alternate"
          href={`${baseUrl}/${otherLocale}/${URL_PATHNAME}/${slug}`}
          hrefLang={otherLocale}
        />
      ))}
      <meta name="robots" content={page.metaRobots.length ? page.metaRobots[0] : "index, follow"} />
      {/* OG */}
      <meta property="og:site_name" content="JobTeaser Corporate" />
      {isArticleType && <meta property="og:type" content="article" />}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={href} />
      <meta property="og:image" content={imageUrl} />
      {imageAlt && <meta property="og:image:alt" content={imageAlt} />}
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      {/* JSON-LD*/}
      {jsonLD && (
        // eslint-disable-next-line react/no-danger
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLD) }} />
      )}
    </Head>
  )
}
