import type { TrackGenericClickEventProps, TrackGenericClickEventType } from "@/modules/tracking/types"

import { trackingRoles } from "@/modules/corporate/tracking/values"
import { useTrackGenericClickEvent } from "@/modules/tracking/useTrackGenericClickEvent"

export const useCorporateTrackingClick = (): TrackGenericClickEventType => {
  const trackEvent = useTrackGenericClickEvent()

  return (type: string, eventProps?: TrackGenericClickEventProps) => {
    const role = trackingRoles[type] || type
    trackEvent(role, { ...eventProps })
  }
}
