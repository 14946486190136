import type { FunctionComponent } from "react"

import classnames from "classnames"

import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { useLocale } from "@/modules/locales/useLocale"

import styles from "./FooterLegals.module.css"

export const FooterLegals: FunctionComponent = () => {
  const { t } = useTranslation(["module_footer_fo"])
  const locale = useLocale()

  return (
    <div className={styles.main}>
      <ul className={styles.links}>
        <li className={styles.item}>
          <a
            rel="noreferrer"
            className={classnames(styles.link, styles.link__small)}
            href="https://status.jobteaser.com/"
          >
            {t("module_footer_fo.status_page")}
          </a>
        </li>
        <li className={styles.item}>
          <a
            rel="noreferrer"
            className={classnames(styles.link, styles.link__small)}
            href={`/${locale}/about/legal-notice`}
          >
            {t("module_footer_fo.legal")}
          </a>
        </li>
        <li className={styles.item}>
          <a rel="noreferrer" className={classnames(styles.link, styles.link__small)} href="#open-cookie-banner">
            {t("module_footer_fo.cookies")}
          </a>
        </li>
        <li className={styles.item}>
          <a
            rel="noreferrer"
            className={classnames(styles.link, styles.link__small)}
            href={`/${locale}/about/privacy-policy`}
          >
            {t("module_footer_fo.privacy_policy")}
          </a>
        </li>
      </ul>

      <div className={styles.copyright}>
        Copyright &copy; JobTeaser 2008-{new Date().getFullYear()} - {t("module_footer_fo.website_goal")}
      </div>
    </div>
  )
}
