import type { LayoutProps } from "@/modules/corporate/components/Layout/types"
import type { Personas } from "@/modules/kontent/generated-types"
import type { FunctionComponent } from "react"

import cn from "classnames"

import IconAfterArrow from "@jobteaser/ui-kit/assets/icons/jds_afterArrow.svg"
import { Icon, ICON_SIZES } from "@jobteaser/ui-kit/components/Icon/Icon"

import { InternalLink } from "@/modules/corporate/components/InternalLink"
import { useCorporateTrackingClick } from "@/modules/corporate/tracking/useCorporateTrackingClick"

import stylesNav from "./Navigation.module.css"

type TopNavigationProps = {
  currentPersona: Personas | undefined
  personas: LayoutProps["personas"]
  onNavigationLinkClick: () => void
}

export const TopNavigation: FunctionComponent<TopNavigationProps> = ({
  currentPersona,
  personas,
  onNavigationLinkClick,
}) => {
  const trackClickEvent = useCorporateTrackingClick()

  return (
    <ul className={stylesNav.list}>
      {personas.map(page => (
        <li
          className={cn(stylesNav.listItem, {
            [stylesNav["listItem--active"]]: currentPersona && page.persona.includes(currentPersona),
          })}
          key={page.title}
        >
          <InternalLink
            page={page}
            className={cn(stylesNav.link, {
              [stylesNav["link--active"]]: currentPersona && page.persona.includes(currentPersona),
            })}
            onClick={() => {
              onNavigationLinkClick()
              trackClickEvent(page.slug)
            }}
          >
            {page.navigationTitle || page.title}
            <Icon className={stylesNav.linkIcon} xlinkHref={`#${IconAfterArrow.id}`} size={ICON_SIZES.MEDIUM} />
          </InternalLink>
        </li>
      ))}
    </ul>
  )
}
