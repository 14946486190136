import type { TrackGenericClickEventProps, TrackGenericClickEventType } from "@/modules/tracking/types"

import { useTrackEvent } from "@/modules/tracking/useTrackEvent"

export const useTrackGenericClickEvent = (): TrackGenericClickEventType => {
  const trackEvent = useTrackEvent()

  return (role: string, eventProps?: TrackGenericClickEventProps) => {
    trackEvent("event_click", { ...eventProps, role, service: "generic_fe" })
  }
}
