import type { RichTextElementProps } from "./types"

export function extractTextsFromRichTextElements(children: RichTextElementProps[]): string[] {
  return (children || []).reduce((prev, next) => {
    const { type } = next

    if (type === "text") {
      const { content } = next

      return [...prev, content]
    }

    if (type === "tag" && next.children) {
      return [...prev, ...extractTextsFromRichTextElements(next.children)]
    }

    return prev
  }, [] as string[])
}

export function getTextFromRichText(children: RichTextElementProps[]): string {
  return extractTextsFromRichTextElements(children).join("-").substring(0, 100)
}
