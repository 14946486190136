import type { Page } from "@/modules/corporate/types/pages"
import type { LinkProps } from "next/link"
import type { AnchorHTMLAttributes, FunctionComponent, PropsWithChildren } from "react"

import { usePathname } from "next/navigation"

import { URL_PATHNAME } from "@/modules/corporate/constants"
import { useLocale } from "@/modules/locales/useLocale"
import { Link } from "@/modules/routing/components/Link"

export type InternalLinkProps = PropsWithChildren<{
  className?: string
  customTitle?: string
  page: Page | { slug: string; title: string }
}> &
  Partial<LinkProps> &
  AnchorHTMLAttributes<HTMLAnchorElement>

export const InternalLink: FunctionComponent<InternalLinkProps> = ({
  children,
  className,
  customTitle,
  page: { slug, title },
  prefetch = false,
  ...props
}) => {
  const pathname = usePathname()
  const locale = useLocale()
  const href = `/${locale}/${URL_PATHNAME}/${slug}`
  const isCurrent = pathname === href

  const allProps = {
    ...props,
  }

  if (isCurrent) {
    allProps["aria-current"] = "page"
  }

  return (
    <Link {...allProps} className={className} href={href} title={title} prefetch={prefetch}>
      {customTitle || children || title}
    </Link>
  )
}
