import type { RichTextElementProps } from "@/modules/kontent/types"
import type { ComponentType, FunctionComponent, ReactNode } from "react"

import { createElement } from "react"

import { Link } from "@/modules/routing/components/Link"

export type RichTextElementCustomProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customComponents?: Record<string, ComponentType<any>>
  tagComponents?: Partial<Record<keyof JSX.IntrinsicElements, ComponentType>>
  textComponent?: ComponentType<{ text: string }>
}

type Props = RichTextElementProps & RichTextElementCustomProps

export const RichTextElement: FunctionComponent<Props> = props => {
  const { type, customComponents, tagComponents = {}, textComponent: TextComponent } = props

  if (type === "text") {
    const { content } = props

    if (content && TextComponent) {
      return <TextComponent text={content} />
    }

    // Fragment is mandatory to trick TypeScript since a plain string is not accepted as return
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return content ? <>{content}</> : null
  }

  if (type === "custom") {
    const { attributes, typeName } = props

    if (!customComponents || !typeName || !customComponents[typeName]) {
      return null
    }

    const Tag = customComponents[typeName]

    return <Tag {...attributes} />
  }

  const { attributes, children, tagName } = props
  const elementChildren = (children || [])
    .map((childProps, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <RichTextElement {...childProps} tagComponents={tagComponents} key={`${childProps.type}_${index}`} />
    ))
    .filter(Boolean) as ReactNode[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let ElementTag: string | ComponentType<any> = tagComponents[tagName as keyof JSX.IntrinsicElements] || tagName

  if (tagName === "a" && `${attributes?.href || ""}`.startsWith("/") && !`${attributes?.href || ""}`.startsWith("//")) {
    ElementTag = Link
  }

  if (typeof ElementTag === "string") {
    return createElement(ElementTag, attributes, ...elementChildren)
  }

  return <ElementTag {...attributes}>{elementChildren}</ElementTag>
}
