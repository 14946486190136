import type { I18nLocale } from "@/modules/i18n/types"
import type { FunctionComponent } from "react"

import cn from "classnames"

import { Dropdown } from "@jobteaser/ui-kit/components/Dropdown"

import { URL_PATHNAME } from "@/modules/corporate/constants"
import { useCorporateTrackingClick } from "@/modules/corporate/tracking/useCorporateTrackingClick"
import { useLocale } from "@/modules/locales/useLocale"
import { Link } from "@/modules/routing/components/Link"

import styles from "./Locales.module.css"

type LocaleProps = {
  isInMenu?: boolean
  pageTranslations: Partial<Record<I18nLocale, string>>
}

export const Locales: FunctionComponent<LocaleProps> = ({ isInMenu = false, pageTranslations }) => {
  const locale = useLocale()
  const trackClickEvent = useCorporateTrackingClick()

  return (
    <Dropdown className={cn(styles.button, { [styles["button--menu"]]: isInMenu })}>
      <Dropdown.TriggerButton
        onClick={() =>
          trackClickEvent("corporate_nav_languages", {
            visual_context: `button`,
          })
        }
      >
        {locale}
      </Dropdown.TriggerButton>
      <Dropdown.Menu>
        {Object.entries(pageTranslations).map(([otherLocale, slug]) => (
          <Dropdown.Item key={otherLocale}>
            <Link
              onClick={() =>
                trackClickEvent("corporate_nav_languages", {
                  clicked_url: `/${otherLocale}/${URL_PATHNAME}/${slug}`,
                  visual_context: `locale_${otherLocale}`,
                })
              }
              href={`/${otherLocale}/${URL_PATHNAME}/${slug}`}
              prefetch={false}
            >
              {otherLocale.toUpperCase()}
            </Link>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
