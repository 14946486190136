import type { Page } from "@/modules/corporate/types/pages"
import type { ResolvedRichTextValue } from "@/modules/kontent/types"
import type { FunctionComponent, PropsWithChildren } from "react"

import cn from "classnames"
import { useRef } from "react"

import { Button } from "@jobteaser/ui-kit/components/Button"
import { Text, TEXT_TYPES, TEXT_WEIGHTS } from "@jobteaser/ui-kit/components/Text/Text"

import { InternalLink } from "@/modules/corporate/components/InternalLink"
import cardBackground from "@/modules/corporate/images/card-background.png"
import { RichText } from "@/modules/kontent/components/RichText"
import { useLazyAssetUrl } from "@/modules/staticAssets/useLazyAssetUrl"

import styles from "./Card.module.css"

export type CardProps = PropsWithChildren<{
  className?: string
  ctaLink?: Page
  ctaText?: string
  description?: string
  isInMenu?: boolean
  richDescription?: ResolvedRichTextValue
  theme: "purple" | "dark" | "light" | string
  onCTAClick?: () => void
  title?: string
}>

export const Card: FunctionComponent<CardProps> = ({
  children,
  className,
  ctaLink,
  ctaText,
  description,
  isInMenu,
  onCTAClick,
  richDescription,
  theme = "light",
  title,
}) => {
  const ref = useRef<HTMLElement>(null)
  const lazyAssetUrl = useLazyAssetUrl({ asset: cardBackground, options: { rootMargin: "200px 0px 200px 0px" }, ref })

  return (
    <article
      className={cn(className, styles.main, { [styles["main--menu"]]: isInMenu })}
      data-theme={theme}
      style={{ "--backgroundImage": `url(${lazyAssetUrl})` } as React.CSSProperties}
      ref={ref}
    >
      {!!title && (
        <Text className={styles.title} type={TEXT_TYPES.HEADING} weight={TEXT_WEIGHTS.BLACK} resetSpacing>
          {title}
        </Text>
      )}
      {!!description && (
        <Text className={styles.text} weight={TEXT_WEIGHTS.BOLD} tag="div" resetSpacing>
          {description}
        </Text>
      )}
      {!!richDescription && (
        <RichText
          value={richDescription}
          wrapperProps={{
            className: styles.text,
            resetSpacing: true,
            tag: "div",
            weight: TEXT_WEIGHTS.BOLD,
          }}
        />
      )}
      {children}
      {!!ctaLink && (
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.button}
            minor={isInMenu}
            component={InternalLink}
            page={ctaLink}
            customTitle={ctaText || ctaLink.menuTitle || ctaLink.title}
            onClick={onCTAClick}
          />
        </div>
      )}
    </article>
  )
}
